import React, { useState } from 'react';
import { Modal, Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import bg2 from '../../assets/bg-2.svg';
import boxImg from '../../assets/box-img.webp';
import EmailService from "../../service/EmailService";

const Footer = () => {

  const [formData, setFormData] = useState({
    name: 'Newsletter Subscription',
    email: '',
    phone: '0000000000',
    formMessage: '',
  });
  const [message, setMessage] = useState({ text: '', variant: '' });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ text: '', variant: '' });

    const currentTimestamp = new Date().toISOString();
    const subscriptionMessage = `Newsletter subscription request from OnlineRoadServices at : ${currentTimestamp}`;

    try {
      const response = await EmailService.sendEmail({ ...formData, formMessage: subscriptionMessage });
      setMessage({ text: response, variant: 'success' });
      setFormData({ name: 'Newsletter Subscription', email: '', phone: '0000000000', formMessage: '' });
    } catch (error) {
      setMessage({ text: `Error: ${error.message}`, variant: 'danger' });
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  };

  return (<>

    <section className='vendor_offer'>
          <div className="shape-img2">
            <img src={bg2} className='img-fluid' alt="figure" width="200"/>
          </div>
          <div className="shape-img1">
            <img src={bg2} className='img-fluid' alt="figure" width="200"/>
          </div>

      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-md-4'>
            <div className='box_Img'>
             <img src={boxImg} className='img-fluid' alt="figure"/>
             </div>
          </div>

          <div className='col-md-6'>
             <div className='ven_ofer_content text-md-left'>
               <h4>Contact us today for special deals!</h4>
               <p>Exclusive listing opportunities to promote your services on our website...</p>
             </div>
          </div>

          <div className='col-md-2'>
              <div className='vendor_register_btn'>
               <a href="tel:+13077760076" className='btn'>Call Now</a>
              </div>
          </div>

        </div>
      </div>

    </section>

    <footer className="text-white pt-5 pb-2">
      <Container>

        <Row>
        <form onSubmit={handleSubmit}>
           <div className="row justify-content-center news_letter_row">

            <div className='col-md-12'>
              <div className='news_title'>
              <h4>Subscribe to our newsletter!</h4>
              </div>
            </div>

            <div className="col-md-4 px-md-0 px-3">
              <div className="form-group news_input">
                 <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                  disabled={loading}
                  />
              </div>
            </div>

            <div className="col-md-2 px-md-0 px-3">
                 <button type="submit" className="btn news_btn" disabled={loading}>
                     {loading ? (
                        <>
                           <Spinner animation="border" size="sm" role="status" className="me-2" />
                           Subscribe
                        </>
                     ) : (
                        'Subscribe'
                     )}
                  </button>
            </div>
           </div>
           </form>
          </Row>

          <hr/>

          <Row>
          <Col className='foot_text my-md-3' md={12}>
            <ul>
              <li><a href="/" className="text-white">Home</a></li>
              <li><a href="/plans" className="text-white">Plans</a></li>
              <li><a href="/about" className="text-white">About Us</a></li>
              <li><a href="/contact" className="text-white">Contact Us</a></li>
              <li><a href="/blogs" className="text-white">Blogs</a></li>
              <li><a href="/privacypolicy" className="text-white">Privacy Policy</a></li>
              <li><a href="/termsandcondition" className="text-white">Terms & Conditions</a></li>
              <li><a href="/refundandcancellation" className="text-white">Refund & Cancellation</a></li>
            </ul>
          </Col>

          <Col className='contact-detail my-md-2' md={12}>
          <ul className="contact-list">
            <li><a href="tel:+13077760076"><i className="fa fa-phone"></i> 307-776-0076</a></li>
            <li><a href="mailto:info@onlineroadservices.com"><i className="fa fa-envelope"></i> info@onlineroadservices.com</a></li>
          </ul>

          </Col>

          <Col className='social-icon mt-3' md={12}>
          <ul>
          <li><h6 className="text-white text-uppercase">Follow Us :</h6></li>
          <li><a href="https://www.facebook.com/onlineroadservices" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
          <li><a href="https://www.instagram.com/onlineroadservices/" target="_blank"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://www.linkedin.com/company/online-road-services/about/?viewAsMember=true" target="_blank"><i className="fab fa-linkedin"></i></a></li>
          <li><a href="https://in.pinterest.com/onlineroadservices/" target="_blank"><i className="fab fa-pinterest"></i></a></li>
          <li><a href="https://www.youtube.com/channel/UCTo2NL2oYsJYipNUgNJz0fg" target="_blank"><i className="fab fa-youtube"></i></a></li>
          <li><a href="https://x.com/ORS_SOS" target="_blank"><i className="fab fa-x-twitter"></i></a></li>
          </ul>
          </Col>
          <Col md={12}>
          <hr/>
          </Col>

          <Col md={12} className="text-center mt-2 copy-right">
            <p>&copy; 2022- {new Date().getFullYear()} OnlineRoadServices. All Rights Reserved.</p>
          </Col>

        </Row>

      </Container>
    </footer>

    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header className='py-2' closeButton>
          <Modal.Title><strong>{message.variant === 'success' ? 'Subscribed Successfully!' : 'Error'}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center my-md-3">
          {message.variant === 'success' ? (
            <>
              <p className="text-success mb-0">😊 Thank you for subscribing! </p>
            </>
          ) : (
            <>
              <p className="text-danger mb-0">❌ {message.text}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Footer;
