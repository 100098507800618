import React from 'react';
import Plans from '../component/plan/Plans';
import ComponenetHeader from '../component/common/ComponenetHeader';
import planIcon1 from '../assets/plan_icon1.webp';
import planIcon2 from '../assets/plan_icon2.webp';
import planIcon3 from '../assets/plan_icon3.webp';

const PlanPage = () => {
  return (
    <>
    <ComponenetHeader
      name={'Plans'}
    />

    <section className='new_plan-section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-10'>
            <div className='plan_new_content'>
            <h1>How does Online Road Services pricing work?</h1>
            <p>Our platform provides vendors with a flexible pay-as-you-go pricing model. You only pay for the
              services <br/>you choose to offer and the time you utilize our platform—no long-term contracts <br/>or
              complicated terms involved.</p>
            </div>
            <div className='plan_new_highlight'>
              <p>Similar to utility bills like water or electricity, you’re billed based on actual usage,
                with no extra fees or termination charges once you stop using the services. Discover how partnering with us can
                streamline your costs and help grow your business. Contact us for a tailored estimate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='new_plan-section_two'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='plan_new_content_two'>
            <h2>How do you pay for Online Road Services?</h2>
            </div>
          </div>

          <div className='col-md-4 mb-md-0 mb-3'>
            <div className='new_plan_card'>
            <img src={planIcon1} className='img-fluid' alt={planIcon1}/>
              <h3>Pay-as-You-Go</h3>
              <p>
              Our pay-as-you-go model empowers you to adapt effortlessly to changing business demands without overcommitting your budget.
              This approach allows you to scale your offerings based on actual needs rather than forecasts, minimizing the risks of
              falling short on capacity. With our flexible pricing, you can respond swiftly to changes, ensuring your
              business stays agile and competitive in a dynamic market.
              </p>
            </div>
          </div>

          <div className='col-md-4 mb-md-0 mb-3'>
            <div className='new_plan_card'>
            <img src={planIcon2} className='img-fluid' alt={planIcon2}/>
              <h3>Save with Commitment</h3>
              <p>
              OnlineRoadServices platform offers savings opportunities when you commit to providing services. By committing to a
              specific level of service usage over a one- or three-year period, you can enjoy reduced costs compared to
              pay-as-you-go pricing. This model ensures predictable expenses while maximizing your earnings potential as a vendor.
              Take advantage of long-term savings by partnering with us today!
              </p>
            </div>
          </div>

          <div className='col-md-4 mb-md-0 mb-3'>
            <div className='new_plan_card'>
            <img src={planIcon3} className='img-fluid' alt={planIcon3}/>
              <h3>Pay less by using more</h3>
              <p>
              With our platform, you can enjoy volume-based discounts and significant savings as your service increases. Our tiered
              pricing model ensures that the more services you provide, the lower your cost per service, helping you maximize
              your profits. We also offer flexible options to support your unique business needs, enabling you to scale seamlessly
              while keeping costs in check. Grow your business and save more with us!
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>

    <Plans/>

    </>
  );
};

export default PlanPage;
