import { BrowserRouter as Router, Routes, Route, Navigate, useParams} from 'react-router-dom';
import './App.css';
import HomePage from './view/HomePage';
import Header from './component/common/Header';
import Footer from './component/common/Footer';
import AdListingPage from './view/AdListingPage';
import AboutUs from './component/aboutUs/AboutUs';
import ContactUs from './component/contactUs/ContactUs';
import PrivacyPolicy from './component/privacyPolicy/PrivacyPolicy';
import TermsAndCondition from './component/termsAndCondition/TermsAndConditon';
import RefundAndCancellation from './component/refundAndCancellation/RefundAndCancellation';
import AdDetailsPage from './view/AdDetailsPage';
import NotFound from './view/NotFound';
import MobileAppLink from './component/mobile-app-link/MobileAppLink';
import BlogListingPage from './view/BlogListingPage';
import BlogPage from './view/BlogPage';
import PlanPage from './view/PlanPage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const scrollToTop = () => {
  window.scroll(0,0)
}

const renderTooltip = (props) => (
  <Tooltip id="tooltip" {...props}>
    Help Center
  </Tooltip>
);

const RedirectOldToNew = () => { 
  const { subcategory, searchlocation } = useParams(); 
  const url = `/${subcategory.toLowerCase()}/service-near-me/${searchlocation.toLowerCase()}`;
  return <Navigate to={url} replace />; 
};

function App() {
  return (
    <div className="App">
          <Router>
            <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<Navigate to="/" replace />} />
                    <Route path="/plans" element={<PlanPage />} />
                    <Route path="/:subcategory/service-near-me/:searchlocation" element={<AdListingPage />} />
                    <Route path="/:subcategory/service-near-me" element={<AdListingPage />} />
                    <Route path="/service-near-me/:subcategory/:searchlocation" element={<RedirectOldToNew />} />
                    <Route path="/details/:adId/:CompanyName/:searchlocation" element={<AdDetailsPage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/app" element={<MobileAppLink />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/termsandcondition" element={<TermsAndCondition />} />
                    <Route path="/refundandcancellation" element={<RefundAndCancellation />} />
                    <Route path="/blogs" element={<BlogListingPage />} />
                    <Route path="/blog/:blogId/:blogTitle" element={<BlogPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
          </Router>

          <button className="scrolltop" onClick={()=>scrollToTop()}><i className="fas fa-arrow-up"></i></button>

          <OverlayTrigger
            placement="left"
            delay={{ show:50, hide: 300 }}
            overlay={renderTooltip}
          >
            <a href="tel:+13077760076" className="float_call">
              <i className="fas fa-headset"></i>
            </a>
          </OverlayTrigger>

      <Footer />
    </div>
  );
}

export default App;
