import React, { useState } from 'react';
import logo from '../../assets/logo.webp';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img className='logo'
            src={logo}
            alt="OnlineRoadService Logo"
             // Adjust size as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto cust-nav">
            <Nav.Link href="/" className={location.pathname === '/home' ? 'active' : ''}>Home</Nav.Link>
            <Nav.Link href="/plans" className={location.pathname === '/plans' ? 'active' : ''}>plans</Nav.Link>
            <Nav.Link href="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Nav.Link>
            <Nav.Link href="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Nav.Link>
            <Nav.Link href="/blogs" className={location.pathname === '/blogs' ? 'active' : ''}>Blog</Nav.Link>
            <Nav.Link className='app_btn' href="/app"><i className="fa fa-mobile-alt"></i> &nbsp;Download App</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
