import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";

const RefundAndCancellation =()=>{

    return(<>
        <ComponenetHeader
           name={'Refund And Cancellation'}
        />

        <section className="content_page text-left">
        <div className="container">
          <div className="row content_page_style justify-content-center">
            <div className="col-md-11">
               <h2 className="mb-4">Refund and Cancellation Policy</h2>
               <h3>Cancellation Policy</h3>
               <ul>
                <li>Subscribers may cancel a service within 15 days of signing the service agreement.</li>
                <li>Services purchased with promotional discounts cannot be canceled, as these are one-time offers and are non-refundable.</li>
                <li>Fees paid are non-refundable, even if the cancellation is requested within 12 hours.</li>
                <li>If the next payment is not received within seven (7) working days, the service will be automatically canceled.</li>
               </ul>
               <h3>Refund Policy</h3>
               <ul>
                <li>Refund requests are accepted only within 15 days of purchase.</li>
                <li>Refunds will not be provided for work already completed, as resources are allocated to achieve each campaign milestone.</li>
                <li>Administrative fees, installation charges, and taxes imposed by regulatory authorities are non-refundable.</li>
                <li>Refunds are not applicable if a service is canceled due to violations of the Terms and Conditions.</li>
                <li>OnlineRoadService is not responsible for refunds in cases of delays or service interruptions caused by third-party involvement.</li>
               </ul>
            </div>
          </div>
         </div>
        </section>
        </>
    );
};

export default RefundAndCancellation;