import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";

const PrivacyPolicy =()=>{

    return(<>

        <ComponenetHeader
           name={'Privacy Policy'}
        />

        <section className="content_page text-left">
         <div className="container">
            <div className="row content_page_style justify-content-center">
             <div className="col-md-11">
               <h2 className="mb-4">Privacy Policy</h2>
               <h3>Introduction</h3>
               <p>At OnlineRoadService, we are dedicated to safeguarding your online privacy and ensuring appropriate protection and management of any personally identifiable information ("Personal Information") you share with us.</p>
               <p>This Privacy Policy outlines our commitment to treating your Personal Information with care and respect.</p>

               <h3>Personal Information</h3>
               <p>Refers to data that can identify an individual, including but not limited to names, physical or email addresses, contact details, and any other relevant information associated with an individual.</p>
               <p>This policy may be updated periodically without prior notice. The latest version will always be posted on this website, and it is recommended to review it before further use of the website. By continuing to use our platform, you agree to be bound by the most recent terms.</p>

               <h3>Cookies</h3>
               <p>"Cookies" are small pieces of data stored on your device by your web browser. These help us recognize returning users and personalize your experience by tailoring offers or content.</p>
               <p>Most browsers accept cookies by default, but you can adjust your settings to block them. Note that disabling cookies may reduce the optimal performance of our website.</p>

               <h3>Use of Your Personal Information</h3>
               <p>We collect and use your Personal Information to:</p>
               <ul>
                <li>Provide and manage our site, products, or services.</li>
                <li>Notify you about updates to our services or your account.</li>
                <li>Fulfill requests or obligations related to agreements between you and us.</li>
                <li>Enable interactive features on our platform.</li>
                <li>Send service-related communications, including renewal and billing notices.</li>
                <li>Improve user experience based on analytics and feedback.</li>
                <li>Comply with legal obligations or other purposes with your consent.</li>
              </ul>

              <h4>We do not sell your Personal Information or share it with others for marketing purposes.</h4>
              <p>Our platform collects data through:</p>
              <ul>
                <li>Website, mobile site, and app usage.</li>
                <li>Communication via email, SMS, or other electronic messages.</li>
                <li>Engagement with our social media platforms like Google+, Facebook, Twitter, LinkedIn, and YouTube.</li>
              </ul>
              <p>Please note that third-party platforms may have their privacy policies, and we are not responsible for their practices.</p>

              <h4>Disclosure of Your Personal Information</h4>
              <p>OnlineRoadService may disclose your Personal Information:</p>
              <ul>
                <li>To third parties when you provide information through co-branded websites.</li>
                <li>To trusted third parties assisting with business operations.</li>
                <li>In case of a business acquisition or transfer of assets.</li>
                <li>When required by law or for legal proceedings.</li>
              </ul>
              <p>We do not sell or distribute Personal Information but may share company names of account holders or vendors listed on our platform. Vendor feedback may also be shared with service providers, with the prior consent of the feedback giver.</p>

              <h4>Consent</h4>
              <p>By providing your Personal Information, you consent to its collection, use, retention, and disclosure as described in this policy.</p>
              <p>We will notify users of significant policy changes through email or website announcements. Please ensure your contact details are up-to-date and regularly review this policy.</p>

              <h4>Security Safeguards</h4>
              <p>Your data's security is a shared responsibility. Protect your account credentials and avoid sharing sensitive information in public forums.</p>
              <p>While we take reasonable measures to secure your data, no internet transmission is entirely secure. Any information shared is at your own risk.</p>

              <h5>Additional Information</h5>
              <p>For questions or concerns regarding this privacy policy, email us at <strong>info@onlineroadservices.com.</strong></p>

             </div>

            </div>
          </div>
        </section>

      </>
    );
};

export default PrivacyPolicy;